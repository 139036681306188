// function changLocalUser(te, val) {
//   if (localStorage.getItem("bros_cash_myuser")) {
//     const _my_user = JSON.parse(localStorage.getItem("bros_cash_myuser"));
//     _my_user[te] = val;
//   } 
// }


function getMyUserLocale(te) {
  if (localStorage.getItem("bros_cash_myuser")) {
    const _my_user = JSON.parse(localStorage.getItem("bros_cash_myuser"));
    return _my_user[te]
  } else  {
    return "visitor"
  }

}

function isLogin() {
  if (localStorage.getItem("bros_cash_myuser")) {
    return 'yes'
  } else  {
    return "no"
  }
}

function checkMaintenance() {
  if (localStorage.getItem("bros_cash_myuser")) {
    const _my_user = JSON.parse(localStorage.getItem("bros_cash_myuser"));
    return _my_user['isMaintenance']
  } else  {
    return false
  }
}

export const _changLocalUser = (te, val) => {
  if (localStorage.getItem("bros_cash_myuser")) {
    const _my_user = JSON.parse(localStorage.getItem("bros_cash_myuser"));
    _my_user[te] = val;
    localStorage.setItem("bros_cash_myuser", JSON.stringify(_my_user));
    setTimeout(() => {
      location.reload();
    }, 500);
  } 
};

export default {
  isLogin: isLogin(),
  id_user: getMyUserLocale('id'), //localStorage.getItem('lang_bros_cash') || 'en',
  user: getMyUserLocale('user'),
  token: getMyUserLocale('token'),
  balance: getMyUserLocale('balance'),
  code_currency: getMyUserLocale('code_currency'),
  check_security_code: getMyUserLocale('check_security_code'),
  isMaintenance: checkMaintenance()
}