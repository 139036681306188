import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import LoginView from '../views/LoginView.vue'

const routes = [
  { path: '/', name: 'home', component: HomeView },
  // { path: '/home', name: 'home', component: () => import('../views/HomeView.vue') },
  { path: '/about', name: 'about', component: () => import('../views/AboutView.vue') },
  { path: '/login', name: 'login', component: () => import('../views/LoginView.vue') },
  { path: '/register', name: 'register', component: () => import('../views/RegisterView.vue') },
  { path: '/restore-password', name: 'restore-password', component: () => import('../views/RestorePasswordView.vue') },
  { path: '/logout', name: 'logout', component: () => import( '../views/LogoutView.vue') },
  { path: '/categories/:id', name: 'categories', component: () => import( '../views/CategoriesView.vue') },
  { path: '/wallet', name: 'wallet', component: () => import( '../views/WalletView.vue') },
  { path: '/profile', name: 'profile', component: () => import( '../views/ProfileView.vue') },
  { path: '/orders', name: 'orders', component: () => import( '../views/OrdersView.vue') },
  { path: '/favorite', name: 'favorite', component: () => import( '../views/FavoriteView.vue') },
  { path: '/authorized-agents', name: 'authorized-agents', component: () => import( '../views/AuthorizedAgentsView.vue') },
  { path: '/charging-methods', name: 'charging-methods', component: () => import( '../views/ChargingMethodsView.vue') },
  { path: '/charging-methods-sub/:id', name: 'charging-methods-sub', component: () => import( '../views/ChargingMethodsSubView.vue') },
  { path: '/charging-methods-send/:id', name: 'charging-methods-send', component: () => import( '../views/ChargingMethodsSendView.vue') },
  { path: '/items/:id', name: 'items', component: () => import( '../views/ItemsView.vue') },
  { path: '/buy/:id', name: 'buy', component: () => import( '../views/BuyingView.vue') },
  { path: '/details/:id', name: 'details', component: () => import( '../views/DetailsView.vue') },
  { path: '/view-order/:id', name: 'view-order', component: () => import( '../views/ViewOrderView.vue') },
  { path: '/debts', name: 'debts', component: () => import( '../views/DebtsView.vue') },
  { path: '/charging-methods-report', name: 'charging-methods-report', component: () => import( '../views/ChargingMethodsReportView.vue') },
  { path: '/card', name: 'card', component: () => import( '../views/CardView.vue') },
  { path: '/send-money', name: 'send-money', component: () => import( '../views/SendMoneyView.vue') },
  { path: '/order-api', name: 'order-api', component: () => import( '../views/OrderApiView.vue') },
  { path: '/disconnect/:ts', name: 'disconnect', component: () => import( '../views/DisconnectView.vue') },
  { path: '/security-code', name: 'security-code', component: () => import( '../views/SecurityCodeView.vue') },
  { path: '/confirm/:id', name: 'confirm', component: () => import( '../views/ConfirmBuyView.vue') },
  { path: '/notifications', name: 'notifications', component: () => import( '../views/NotificationsView.vue') },
  { path: '/messages', name: 'messages', component: () => import( '../views/MessagesView.vue') },
  { path: '/activity', name: 'activity', component: () => import( '../views/ActivityView.vue') },
  { path: '/api', name: 'api', component: () => import( '../views/APIProtocolView.vue') },



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
