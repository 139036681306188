<template>
  <SpinnerComponent :start="isLoading" />
  <div class="p--home">

    <swiper v-if="isAds" :slides-per-view="1" :space-between="50" class="box-ads-swiper">
      <swiper-slide v-for="a in ads" :key="a" class="item-ads-swiper">
        <img alt="default" :src="a['Image']">
      </swiper-slide>
    </swiper>

    <div class="group-settings">
      <h5>.</h5>
      <div class="btns">
        <span class="btn-settings" @click="goToPage('notifications')"><ion-icon class="icon" name="notifications-outline"></ion-icon></span>
        <span class="btn-settings" @click="goToPage('messages')"><ion-icon class="icon" name="mail-outline"></ion-icon></span>
      </div>
    </div>

    <div class="box-marquee" v-if="isNews">
      <marquee direction="right">
        <span class="text" v-for="n in news" :key="n">{{ n.Details }} <span class="m"></span></span>
      </marquee>
    </div>

    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="content-category-parent" v-if="isCategoriesParent">
      <div class="item-category-parent" v-for="x in categoriesParent" :key="x" @click="say(x)">
        <img class="category-parent-img" alt="Vue logo" :src="x.Image">
        <div class="info">
          <h3 class="category-parent-name">{{ x.Name }}</h3>
          <p class="category-parent-details">{{ x.Details }}</p>
        </div>
        <span>{{ x.countItems }}</span>
      </div>
    </div>

    <div class="content-empty-items" v-if="!isCategoriesParent">
      <div><ion-icon name="cloud-offline-outline"></ion-icon></div>
      <p class="products-name">{{ $t('_public._there_are_no_data') }}</p>
    </div>
<!-- 
    <div class="content-category">
      <div class="item-category" v-for="x in categories" :key="x" @click="say(x)">
        <img class="category-img" alt="Vue logo" :src="x.Image">
        <h3 class="category-name">{{ x.Name }}</h3>
      </div>
    </div> -->

  </div>

  
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import SpinnerComponent from '@/components/SpinnerComponent.vue'
import axios from 'axios';
import router from "@/router";

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import myUser, { _changLocalUser } from '../myUser'
import { routerAuth } from '../auth'
import { showSidebar } from '@/components/sidebar/state'
import Swal from 'sweetalert2'

import { toast } from "vue3-toastify";
import 'vue3-toastify/dist/index.css'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
    SpinnerComponent,
    Swiper,
    SwiperSlide
  },
  data() {
    
    return {
      lang: this.$i18n.locale,
      isLoading: true,
      categoriesParent: null,
      isCategoriesParent: false,
      categories: null,
      isCategories: false,

      ads: null,
      isAds: false,

      news: null,
      isNews: false,
    }
  },
  methods: {
    async checkLogin() {
      // _changLocalUser("isMaintenance", false);
      const check = routerAuth();
      if(check == 'yes'){
        this.getDataView()
        
        // console.log("locale", this.$i18n.locale)
      }
      // if (myUser.isLogin == 'yes') {
      //   // console.log("app", myUser)
      //   if (myUser.check_security_code == 'yes') {
      //     router.push({ path: '/security-code' }) 
      //   } else {
      //     this.getDataView()
      //   }
      // } else {
      //   router.push({ path: '/login' }) 
      // }
    },
    async getDataView(){
      this.checkStatusUser();
      this.getAllCategoriesParent();
      this.getAllViewAds();
      this.getAllViewNews()
      this.getAllMessagesPop()
      this.checkMaintenance();
      this.getVersionApp();
    },
    async checkStatusUser(){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('auth.php?action=checkStatusUser', inputs).then((res) => {
        // console.log("checkStatusUser", res['data'])
        if (res['data']['msg'] !== 'ok') {

          localStorage.removeItem("bros_cash_myuser");
          toast.success(this.$t('_home._success_logout'), {autoClose: 1000,});
          this.isLoading = false;

          setTimeout(() => {
            location.reload();
          }, 1000);

        }
      }).catch(() => { router.push({ path: `/disconnect/network` }) });
    },
    async getAllCategoriesParent(){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('categories.php?action=getAllCategoriesParent', inputs).then((res) => {
        // console.log(res['data'])
        if (res['data']['msg'] == 'ok') {
          // console.log(res)
          // console.log(res['data']['items'])
          this.categoriesParent = res['data']['items'];
          this.isCategoriesParent = true;
          this.isLoading = false;

        } else {
          this.isCategoriesParent = false;
          // console.log(res['data']['msg']);
          this.isLoading = false;
        }
      }).catch(() => { router.push({ path: `/disconnect/network` }) });
    },
    async getAllViewAds(){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('ads.php?action=getAllViewAds', inputs).then((res) => {
        // console.log("getAllViewNews", res['data'])
        if (res['data']['msg'] == 'ok') {
          // console.log(res)
          // console.log(res['data']['items'])
          this.ads     = res['data']['ads'];
          this.isAds   = true;
          this.isLoading= false;

        } else {
          this.isAds = false;
          // console.log(res['data']['msg']);
          this.isLoading = false;
        }
      }).catch(() => { router.push({ path: `/disconnect/network` }) });
    },
    async getAllViewNews(){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('ads.php?action=getAllViewNews', inputs).then((res) => {
        // console.log("getAllViewNews", res['data'])
        if (res['data']['msg'] == 'ok') {
          // console.log(res)
          // console.log(res['data']['items'])
          this.news     = res['data']['ads'];
          this.isNews   = true;
          this.isLoading= false;

        } else {
          this.isNews = false;
          // console.log(res['data']['msg']);
          this.isLoading = false;
        }
      }).catch(() => { router.push({ path: `/disconnect/network` }) });
    },
    async getAllMessagesPop(){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('messages.php?action=getAllMessagesPop', inputs).then((res) => {
        // console.log("getAllMessagesPop", res['data'])
        if (res['data']['msg'] == 'ok') {
          res['data']['items'].forEach(pop => {
            let id_message  = pop['ID'];
            let title       = pop['Title'];
            let message     = pop['Message'];
            // let in_image  = pop['in_Image'];
            let image       = pop['Image'];

            this.viewPop(id_message, title, message, image)

          });


        } else {
          // console.log(res['data']['msg']);
        }
      }).catch(() => { router.push({ path: `/disconnect/network` }) });
    },
    async viewPop(id_message, title, text, imageUrl){
      Swal.fire({
        title: title, //'Sweet!',
        text: text, //'Modal with a custom image.',
        imageUrl: imageUrl, //'https://unsplash.it/400/200',
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Custom image',
        showClass: {
          popup: 'messages__pop'
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log("viewPop")
          this.readMessagesPop(id_message);
        }
      })
    },
    async readMessagesPop(id_message){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token, 'id_message': id_message} 
      await axios.postForm('messages.php?action=readMessagesPop', inputs).then((res) => {
        console.log("readMessagesPop", res['data'])
      }).catch(() => { 
        //router.push({ path: `/disconnect/network` }) 
      });
    },
    async checkMaintenance(){
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('app_settings.php?action=checkMaintenance', inputs).then((res) => {
        // console.log("getAllMessagesPop", res['data'])
        if (res['data']['msg'] == 'ok') {
          // var details   = res['data']['items']['Details'];
          // var downTime  = res['data']['items']['DownTime'];
          // var dtime     = res['data']['items']['dtime'];
          // router.push({ path: `/disconnect/maintenance` }) 
          // const _my_user = JSON.parse(localStorage.getItem("bros_cash_myuser"));
          // _my_user['isMaintenance'] = true;
          // localStorage.setItem("bros_cash_myuser", JSON.stringify(_my_user));

          // setTimeout(() => {
          //   location.reload();
          // }, 1000);

          _changLocalUser("isMaintenance", true);
          
        } else {
          // console.log(res['data']['msg']);
        }
      }).catch(() => { 
        //router.push({ path: `/disconnect/network` }) 
      });

      // this.providApi.checkMaintenance(idus, token).subscribe((res:any) => {
      //   // console.log(res);
      //   if (res['msg'] == 'ok') {
      //     var details   = res['items']['Details'];
      //     var downTime  = res['items']['DownTime'];
      //     var dtime     = res['items']['dtime'];

      //     this.router.navigate(['/disconnect-internet', {'action': 'maintenance', 'details': details, 'downTime': downTime, 'dtime': dtime }]); 
      //   }
      // })
    },
    async getVersionApp() {
      let app_ver   = this.$t('_public._app_ver');
      const inputs  = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token, 'ver': app_ver} 
      await axios.postForm('app_settings.php?action=getVersionAppNew', inputs).then((res) => {
        // console.log("getAllMessagesPop", res['data'])
        if (res['data']['status'] == 'update') {
          router.push({ path: `/disconnect/update` }) 
        }

      }).catch(() => { 
        //router.push({ path: `/disconnect/network` }) 
      });
    },
    // async getAllCategories(){
    //   // this.isLoading = false;
    //   const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
    //   await axios.postForm('categories.php?action=getAllCategories', inputs).then((res) => {
    //     console.log(res['data'])
    //     if (res['data']['msg'] == 'ok') {
    //       // console.log(res)
    //       console.log(res['data']['items'])
    //       this.categories = res['data']['items'];
    //       this.isCategories = true;

    //     } else {
    //       this.isCategories = false;
    //       console.log(res['data']['msg']);
    //       // this.isLoading = true
    //     }
    //   }).catch(() => {  });
    // },
    async goToPage(page){
      // console.log(page);
      router.push({ path: `/${page}` })
    }
  },
  mounted(){
    showSidebar()
    router.replace()
    this.checkLogin()
    // this.getAllCategoriesParent();
    // this.getAllCategories();
  },
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   console.log('slide change');
    // };

    const say = (item) => {
      const iditem = item['ID'];
      router.push({ path: `/categories/${iditem}` })
    }

    return {
      // onSwiper,
      // onSlideChange,
      say,
    };
  },
}
</script>
<style>
.group-settings {
  background: var(--box-background);
  margin: 12px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 7px;
  box-shadow: 0px 5px 12px rgba(var(--color-box-shadow-rgb), 0.08);
}
.group-settings h5 {
  font-size: 13px;
  color: var(--color-text);
}
.group-settings .btns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.group-settings .btns .btn-settings {
  min-width: 34px;
  height: 34px;
  padding: 0px 7px;
  border: 1px solid var(--public);
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 5px;
  margin: 0px 3px;
  cursor: pointer;
  color: var(--color-text);
}
.group-settings .btns .btn-settings .icon:hover  {
  transform: scale(1.1);
}
.group-settings .btns .btn-settings .icon {
  font-size: 16px;
}
/* .box-ads-swiper {
}
.item-ads-swiper {
} */
.box-ads-swiper {
  border-radius: 15px;
}
.box-ads-swiper .item-ads-swiper{
  border-radius: 15px;
}
.box-ads-swiper .item-ads-swiper img {
  max-height: 275px;
  width: 100%;
  border-radius: 15px;
}
.box-marquee {
  background: var(--box-background);
  margin: 12px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 7px;
  box-shadow: 0px 5px 12px rgba(var(--color-box-shadow-rgb), 0.08);
  min-height: 46px;
}
.box-marquee marquee {
  direction: initial;
}
.box-marquee marquee .text .m {
  padding: 0px 12px;
}

.content-category-parent {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px 0px;
}
.content-category-parent .item-category-parent {
  position: relative;
  min-height: 94px;
  background: var(--box-background);
  width: 100%;
  padding: 15px;
  margin-bottom: 2%;
  border-radius: 12px;
  box-shadow: 0px 5px 20px rgba(var(--color-box-shadow-rgb), 0.08);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  transition: 0.2s;
}
.content-category-parent .item-category-parent:hover {
  box-shadow: 0px 5px 20px rgba(var(--color-box-shadow-rgb), 0.18);
  transform: scale(1.01);
  transition: 0.2s;
}
.content-category-parent .item-category-parent .category-parent-img {
  min-height: 65px;
  height: 65px;
}
.content-category-parent .item-category-parent .info {
  padding: 0px 9px;
  width: calc(100% - 130px);
  text-align: start;
}
.content-category-parent .item-category-parent .info .category-parent-name {
  font-size: 13px;
  color: var(--color-text);
}
.content-category-parent .item-category-parent .info .category-parent-details {
  font-size: 12px;
  color: var(--color-text);
}
.content-category-parent .item-category-parent span {
  width: 50px;
    height: 50px;
    border-radius: 15px;
    display: flex;
    box-shadow: 9px 0px 9px rgba(var(--color-box-shadow-rgb), 0.08);
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    color: var(--public);
    font-weight: 600;
}




.content-category {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px 0px;
}
.content-category .item-category {
  position: relative;
  min-height: 140px;
  background: var(--box-background);
  width: calc(92% / 4);
  padding: 15px;
  margin-bottom: 2%;
  border-radius: 12px;
  box-shadow: 0px 5px 20px rgba(var(--color-box-shadow-rgb), 0.08);
  cursor: pointer;
}
.content-category .item-category:hover {
  box-shadow: 0px 5px 20px rgba(var(--color-box-shadow-rgb), 0.15);
}
.content-category .item-category .category-img {
  width: 100%;
  border-radius: 12px;
}
.content-category .item-category .category-name {
  color: var(--color-text);
  font-size: 12px;
  padding-top: 5px;
}
</style>
