<template >
  <div class="sidebar" :style="{ width: sidebarWidth }" v-if="isDisplaySidebar"> 
    <h1 class="name-web">
      <span v-if="collapsed">
        <div>{{ $t('_public._name_app_a') }}</div>
        <div>{{ $t('_public._name_app_b') }}</div>
      </span>
      <span v-else>{{ $t('_public._name_app') }}</span>
    </h1>

    <SidebarLink to="/" icon="home-outline">{{ $t('_sidebar._Home') }}</SidebarLink>
    <SidebarLink to="/categories/all" icon="grid-outline">{{ $t('_sidebar._Categories') }}</SidebarLink>
    <SidebarLink to="/wallet" icon="wallet-outline">{{ $t('_sidebar._Wallet') }}</SidebarLink>
    <SidebarLink to="/orders" icon="bag-outline">{{ $t('_sidebar._Orders') }}</SidebarLink>
    <SidebarLink to="/favorite" icon="heart-outline">{{ $t('_sidebar._Favorite') }}</SidebarLink>
    <SidebarLink to="/profile" icon="person-outline">{{ $t('_sidebar._Profile') }}</SidebarLink>
    <SidebarLink to="/authorized-agents" icon="people-outline">{{ $t('_sidebar._Authorized_Agents') }}</SidebarLink>
    <SidebarLink to="/charging-methods" icon="cash-outline">{{ $t('_sidebar._Charging_Methods') }}</SidebarLink>
    <SidebarLink to="/activity" icon="document-text-outline">{{ $t('_sidebar._Activity') }}</SidebarLink>
    <SidebarLink v-if="isAPI === true" to="/api" icon="hardware-chip-outline">API</SidebarLink>
    <SidebarLink to="/about" icon="settings-outline">{{ $t('_sidebar._About') }}</SidebarLink>
    <SidebarLink v-if="isLogin === 'yes'" to="/logout" icon="log-out-outline">{{ $t('_sidebar._Logout') }}</SidebarLink>
    <SidebarLink v-if="isLogin === 'no'" to="/login" icon="log-out-outline">{{ $t('_sidebar._Login') }}</SidebarLink>

    <span class="content-mode-theme"><ion-icon @click="switchTheme" id="icon-theme" name="moon-outline"></ion-icon></span>
    
    <div class="locale-changer">
      <select id="select-lang-public" v-model="$i18n.locale" @change="changeSwitchLang">
        <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
      </select>
    </div>

    <span 
    class="collapse-icon" 
    :class="{ 'rotate-180': collapsed }"
    @click="toggleSidebar"
    >
    X</span>
  </div>
</template>
  
<script>
import SidebarLink from './SidebarLink';
import { collapsed, toggleSidebar, sidebarWidth, isDisplaySidebar} from './state';
import myUser from '../../myUser';
import axios from 'axios';

export default {
  name: 'SideBar',
  props: {
    msg: String
  },
  data() {

    return {
      isLogin: myUser.isLogin,
      isAPI: false
    }
  },
  methods: {
    async checkAPIUser(){
      // this.isLoading = false;
      const inputs = {'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('app_settings.php?action=checkAPIUser', inputs).then((res) => {
        // console.log(res['data'])
        if (res['data']['msg'] == 'ok') {
          this.isAPI = true;
        } else {
          this.isAPI = false;
        }
      }).catch(() => {  });
    },
    async switchTheme(){
      // console.log("switchTheme")
      const bodyPage  = document.body;
      const iconTheme = document.getElementById("icon-theme");

      if (bodyPage.classList.contains("dark-theme")) { 
        bodyPage.classList.toggle("dark-theme");
        iconTheme.setAttribute("name", "moon-outline");
        localStorage.setItem("dark_mode_bros_cash", "light")
      } else {
        bodyPage.classList.toggle("dark-theme");
        iconTheme.setAttribute("name", "sunny-outline");
        localStorage.setItem("dark_mode_bros_cash", "dark")
      }
      
      // const dartMode  = localStorage.getItem("dark_mode_bros_cash");
      // console.log(dartMode);
    },
    async changeSwitchLang(e) {
      const n_lang = e.target.value;
      // console.log("change", n_lang)
      localStorage.setItem("lang_bros_cash", n_lang);
      if(n_lang === 'ar'){
        document.body.classList.remove("ltr");
        document.body.classList.add("rtl");
      } else {
        document.body.classList.remove("rtl");
        document.body.classList.add("ltr");
      }
    },
    // checkLogin(){
    //   this.isLogin = false
    //   // return true
    // }
  },
  mounted(){
    this.checkAPIUser()
  },
  components: { SidebarLink },
  setup(){
    return { collapsed, toggleSidebar, sidebarWidth, isDisplaySidebar }
  }
}
</script>

<style scoped>
.sidebar {
  color: var(--color-text);
  /* background-color: var(--sidebar-bg-color); */
  background-color: var(--box-background);
  /* float: left; */
  position: sticky;
  z-index: 1;
  top: 0;
  /* left: 0; */
  bottom: 0;
  padding: 0.5em;

  transition: 0.3s ease;

  display: flex;
  flex-direction: column;
  height: 100vh;
}
.sidebar .name-web {
  font-size: 16px;
  margin: 26px 0px;
  text-transform: uppercase;
}
.collapse-icon {
  position: absolute;
  bottom: 0;
  padding: 0.75em;
  color:  var(--color-text);
  cursor: pointer;
  transition: 0.2s linear;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
.content-mode-theme {
  margin-top: 15px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
}
.content-mode-theme  #icon-theme {
  width: 40px;
  font-size: 16px;
  cursor: pointer;
}
/* body.rtl .sidebar {
  left: unset;
  right: 0;
} */
.locale-changer {
  position: relative;
  width: 100%;
  border: 1px solid var(--color-text);
  margin-bottom: 12px;
  border-radius: 5px;
}
.locale-changer select {
  border: unset;
  width: 96%;
  color: var(--color-text);
  outline: unset;
  background: transparent;
  height: 22px;
}
.locale-changer select option {
  margin: 40px;
  background: var(--box-background);
  color: var(--color-text);
  text-shadow: unset;
}
/* body.rtl .content-mode-theme {
  right: unset;
  left: -20px;
  box-shadow: -8px -4px 13px rgba(var(--color-box-shadow-rgb), 0.2);
} */
</style>
  