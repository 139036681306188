import myUser from './myUser'
import router from "@/router";

export const routerAuth = () => {

  if (myUser.isLogin == 'yes') {
    // console.log("app", myUser)
    if (myUser.check_security_code == 'yes') {

      router.push({ path: '/security-code' }) 
      return 'no';

    } else {

      if(myUser['isMaintenance'] === true){

        router.push({ path: `/disconnect/maintenance` })
        return 'no';

      } else {
        // router.push({ path: '/' }) 
        return 'yes';
      }

    }

  } else {
    router.push({ path: '/login' }) 
    return 'no';
  }

}