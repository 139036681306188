import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import i18n from './i18u'
import myUser from './myUser'

import axios from 'axios'


// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST, PATCH, PUT, DELETE, OPTIONS';
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Access-Control-Allow-Headers, Content-Type, Authorization';
axios.defaults.headers.common['Content-type'] = 'application/json';
// axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
// axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://www.ak-cash.com/aaa/api_web/';

createApp(App).use(router).use(i18n).use(myUser).mount('#app')
