import { ref, computed } from "vue";

export const isDisplaySidebar = ref(true)
export const showSidebar = () => (isDisplaySidebar.value = true);
export const hideSidebar = () => (isDisplaySidebar.value = false);

export const collapsed = ref(true)
export const toggleSidebar = () => (collapsed.value = !collapsed.value)

export const SIDEBAR_WIDTH = 180
export const SIDEBAR_WIDTH_COLLAPSED = 60
export const sidebarWidth = computed(()=> `${collapsed.value ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH}px`)